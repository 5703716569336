export const settings = {
  apiURL: "https://library-app-backend-v1.herokuapp.com",
  siteName: "academatech",
  siteUrl: "academatech.com",
  email1: "support@academatech.com",
  email2: "contact@academatech.com",
  phone1: "8801 923 154 367",
  phone2: "8801 923 154 374",
  address: "244 Perry Street, Trenton, NJ, USA",
  mapEmbedUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3046.3151609991837!2d-74.76253838429787!3d40.22429437524388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c159cb00d415c7%3A0x3094e04878e3d499!2s244%20Perry%20St%2C%20Trenton%2C%20NJ%2008618%2C%20USA!5e0!3m2!1sen!2str!4v1663792628886!5m2!1sen!2str",
  workSchedule: "9:30am-5:30pm",
};